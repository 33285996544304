import { TransactionProduct } from "@/types/transaction/Transaction.interface";
import { TransactionDetail } from "@/types/transaction/TransactionDetail.interface";
import { Freeproducts, ProductDiscount, Promotions } from "@/types/transaction/TransactionPromotion";
import { Address } from "@/types/transaction/TransactionAddress.interface";
export type State = {
    transaction: TransactionDetail
}
export const state: State = {
    transaction: {
        id: 0,
        brandId: 0,
        branchId: 0,
        statusId: 0,
        statusName: "",
        invoiceNo: "",
        note: "",
        paymentMethodId: 0,
        paymentName: "",
        paymentReference: "",
        checkSum: "",
        trackingNo: {
            urlLink: "",
            text: ""
        },
        buyerInfo: {
            userInfo: {
                telephone: "",
                firstname: "",
                lastname: "",
                email: "",
                socialMediaId: 0,
                socialMediaName: "",
                receivedMarketing: false,
                socialSalesChannelId: 0,
                theOneCardRef: "",
                socialSourceId: 0,
                socialReferenceId: "",
                isRequestTaxInvoice: false,
                socialName: "",
                profilePictureUrl: ""
            },
            billingAddressId: 0,
            shippingAddressId: 0,
            shippingAddress: {
                firstname: "",
                lastname: "",
                address: "",
                road: "",
                province: "",
                district: "",
                subdistrict: "",
                zipcode: "",
                tax: "",
                nameTitleId: 0,
                id: 0,
                shippingNote:"" ,
            } as Address,
            billingAddress: {
                id: 0,
                firstname: "",
                lastname: "",
                address: "",
                road: "",
                province: "",
                district: "",
                subdistrict: "",
                zipcode: "",
                tax: "",
                nameTitleId: 0,
                isRequestTax: false
            },
            sameAddress: true,
            isConsent: true,
            isRequestTax: false
        },
        reasonNote: "",
        reasonId: 0,
        socialRef: {
            lineLiffId: "",
            facebookRefId: ""
        },
        canDownloadJobSheet: false,
        canUpdateTracking: false,
        expiredDate: "",
        createdTime: "",
        replyReferenceId: "",
        replyTypeId: 0,
        userGUID: "",
        shippingTypeId: 0,
        shippingTypeName: "",
        isWiatForPushTrackingMessage: false,
        pushTrackingMessageTime: "",
        products: [] as TransactionProduct[],
        promotions: [] as Promotions[],
        freeProducts: [] as Freeproducts[],
        productDiscount: [] as ProductDiscount[],
        isFreeProduct: false,
        discount: 0.0,
        discountPromotion: 0.0,
        grandTotal: 0,
        weightTotal: 0.0,
        shippingCost: 0,
        discountShipping: 0.0,
        total: 0,
        distance: 0.0,
        saleChannel: 1,
        countFreeProduct: 0,
        totalQuantity: 0
    }
};
