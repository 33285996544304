import { ActionTree, ActionContext } from 'vuex';

import { RootState, useStore } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { TransactionActionTypes } from './action-types';
import { BrandActionTypes } from '../brand/action-types';
import TransactionService from '@/service/transaction-service/TransactionService';
import { TransactionDetail } from '@/types/transaction/TransactionDetail.interface';
import { TransactionMutationTypes } from './mutation-types';
import { AddressGuest, BuyerInformation } from '@/types/transaction/TransactionAddress.interface';
import { GlobalActionTypes } from '../global/action-types';
import { useCookies } from "vue3-cookies";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>


export interface Actions {
    [TransactionActionTypes.SET_TRANSACTION](
        { commit }: AugmentedActionContext,
        value: string, // Obsolete in here but left as an example
    ): void
    [TransactionActionTypes.SET_BUYER_INFO](
        { commit }: AugmentedActionContext,
        value: string, // Obsolete in here but left as an example
    ): void
    [TransactionActionTypes.SET_BUYER_INFO_BY_SOCIAL_REF_ID](
        { commit }: AugmentedActionContext,
        value: string, // Obsolete in here but left as an example
    ): void
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [TransactionActionTypes.SET_TRANSACTION]({ commit, state }, value: string) {
        const transactionService = new TransactionService()
        const store = useStore()
        const cookies = useCookies()


        await transactionService.getOrderDetailById(value).then(async (data: any) => {

            const hasShippingAddress = data.buyerInfo.shippingAddress && data.buyerInfo.shippingAddress.zipcode;
            const hasUserInfo = data.buyerInfo.userInfo && data.buyerInfo.userInfo.firstname;

            if (hasUserInfo && data.buyerInfo.userInfo.socialReferenceId) {
                store.dispatch(GlobalActionTypes.SET_TRANSACTION_PLATFORM, data.buyerInfo.userInfo.socialSourceId)
            }
            store.dispatch(BrandActionTypes.GET_BRAND_CONFIG, data.brandId)
            
            if (store.getters.getSessionId && hasShippingAddress && !data.buyerInfo.shippingAddress.zipcode) {
                await store.dispatch(TransactionActionTypes.SET_BUYER_INFO, store.getters.getSessionId)
                await commit(TransactionMutationTypes.SET_TRANSACTION, data);
            }
            else if (hasUserInfo && data.buyerInfo.userInfo.socialReferenceId && hasShippingAddress && !data.buyerInfo.shippingAddress.zipcode) {
                await store.dispatch(TransactionActionTypes.SET_BUYER_INFO_BY_SOCIAL_REF_ID, data.buyerInfo.userInfo.socialReferenceId)
                await commit(TransactionMutationTypes.SET_TRANSACTION, data);
            } else if (!data.buyerInfo.shippingAddress.zipcode && !data.buyerInfo.billingAddress.zipcode  && store.getters.getPlatform == 0) {
                const cookieAddress = JSON.parse(cookies.cookies.get("tnl-address-list")) as AddressGuest[];

                if (cookieAddress && cookieAddress.length > 0) {
                    const buyerInfo = cookieAddress.find(el => el.brandId == data.brandId)

                    if (buyerInfo) {
                        data.buyerInfo = buyerInfo.buyerInfo
                    }
                }

                await commit(TransactionMutationTypes.SET_TRANSACTION, data);
            } else {
               await commit(TransactionMutationTypes.SET_TRANSACTION, data);
            }
        })
    },
    async [TransactionActionTypes.SET_BUYER_INFO]({ commit, state }, value: string) {
        const transactionService = new TransactionService()
        await transactionService.getAddressUserDetail(value).then((data: BuyerInformation) => {
            state.transaction.buyerInfo.userInfo = data.userInfo
            state.transaction.buyerInfo.billingAddressId = data.billingAddressId
            state.transaction.buyerInfo.isConsent = data.isConsent
            state.transaction.buyerInfo.isRequestTax = data.isRequestTax
            state.transaction.buyerInfo.sameAddress = data.sameAddress
            state.transaction.buyerInfo.shippingAddressId = data.shippingAddressId
        })
    },
    async [TransactionActionTypes.SET_BUYER_INFO_BY_SOCIAL_REF_ID]({ commit, state }, value: string) {
        const transactionService = new TransactionService()
        await transactionService.getBuyerInfoBySocialReferenceId(value).then((data: BuyerInformation) => {
            if (data.userInfo) {
                commit(TransactionMutationTypes.SET_BUYER_INFO, data);
            }
        })
    },

};
